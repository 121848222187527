.App {
  text-align: center;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
}

.App-faq {
  text-align: center;
  background-color: #EFEEF3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
}


.App-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}